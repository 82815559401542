import { ManagementSuperVisor } from './../models/sdm/management-supervisor/management-supervisor';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SDM_URL } from './../../app.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagementSupervisorService extends AbstractService<
  ManagementSuperVisor
> {
  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.managementSupervisor.default);
  }

  findByDepositorAndSolution(
    depositorId: number,
    solutionId: number
  ): Observable<ManagementSuperVisor[]> {
    return this.http
      .get<ManagementSuperVisor[]>(
        `${SDM_URL.managementSupervisor.findByDepositorAndSolution
          .replace(':depositorId', depositorId.toString())
          .replace(':solutionId', solutionId.toString())}`
      )
      .pipe(take(1));
  }

  validateSupervisorPass(
    establishmentId: number,
    depositorId: number,
    solutionId: number,
    passwd: string
  ): Observable<ManagementSuperVisor> {
    return this.http.get<ManagementSuperVisor>(
      `${SDM_URL.managementSupervisor.validateSupervisorPass
        .replace(':establishmentId', establishmentId.toString())
        .replace(':depositorId', depositorId.toString())
        .replace(':solutionId', solutionId.toString())}?passwd=${passwd}`
    );
  }
}
