import { UserDomain } from "./user-domain.model";
import { Solution } from "./solution.model";
import { AbstractStatusModel } from "./abstractStatus.model";

export class DomainModel {

    public id: number;

	public description: String;

	public pathName: String;

	public name: String;

	public password: String;

	public statusId: number;

	public usersDomain: UserDomain[];

	public solutions: Solution[];

	public status: boolean;

	public validityIni: String;

	public validityFim: String;

	public integration: boolean;

    constructor() {
        this.solutions = new Array() as Array<Solution>;
		this.usersDomain = new Array() as Array<UserDomain>;
		this.status = true;
		this.integration = false;
    }
}