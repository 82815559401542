


import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../../../environments/environment";
import { UserDomain } from "../../models/user-domain.model";
import { AbstractService } from "../abstract.service";

@Injectable()
export class FileManagementService extends AbstractService<UserDomain> {

  constructor(public http: HttpClient) {
    super(http, env.host.sbaw, "/wrapper/sbaw/file-management");
  }

  download(pathWithParams: String) {
    return this.http.get(`${env.host.sbaw}${pathWithParams}`, { responseType: "blob" });
  }

}
