import { ColorCodeParametrization } from "../color-code-parametrization";

export class FilterScoreDTO {

    public totalLines: number;

    public hu: number;

    public parts: number;

    public destinations: number;

    public products: number;

    public weight: number;

    public cubicMeter: number;

    public orders: number;

    public linesOrdersValue: number;
    public linesOrdersColor: any;

    public huLinesValue: number;
    public huLinesColor: number;

    public unitsLinesValue: number;
    public unitsLinesColor: number;

    constructor() {
        this.orders = 0;
        this.products = 0;
        this.parts = 0.00;
        this.hu = 0.00;
        this.totalLines = 0.00;
        this.weight = 0.00;
        this.cubicMeter = 0.00;
        this.destinations = 0.00;

        this.linesOrdersValue = 0.00;
        this.linesOrdersColor = -1;

        this.huLinesValue = 0.00;
        this.huLinesColor = -1;

        this.unitsLinesValue = 0.00;
        this.unitsLinesColor = -1;
    }

    setFilter(filter: FilterScoreDTO) {

        this.orders = filter.orders;
        this.products = filter.products;
        this.parts = filter.parts;
        this.hu = filter.hu;
        this.totalLines = filter.totalLines;
        this.weight = filter.weight;
        this.cubicMeter = filter.cubicMeter;
        this.destinations = filter.destinations;

        this.linesOrdersValue = 0.00;
        this.linesOrdersColor = -1;

        this.huLinesValue = 0.00;
        this.huLinesColor = -1;

        this.unitsLinesValue = 0.00;
        this.unitsLinesColor = -1;
    }

    calculateStatistic() {
        this.linesOrdersValue = (this.totalLines > 0) ? this.totalLines / this.orders : 0.00;
        this.huLinesValue = (this.totalLines > 0) ? this.hu / this.totalLines : 0.00;
        this.unitsLinesValue = (this.totalLines > 0) ? this.parts / this.totalLines : 0.00;
    }

    setColorCode(colorCodeParametrization: ColorCodeParametrization) {
        let checkObject = [
            {
                redType: colorCodeParametrization.redLinesOrders,
                redValue: colorCodeParametrization.redValueLineOrders,
                greenType: colorCodeParametrization.greenLinesOrders,
                greenValue: colorCodeParametrization.greenValueLineOrders,
                prop: 'linesOrdersValue',
                propColor: 'linesOrdersColor'
            }, {
                greenType: colorCodeParametrization.greenHuLines,
                greenValue: colorCodeParametrization.greenValuehuLines,
                redType: colorCodeParametrization.redHuLines,
                redValue: colorCodeParametrization.redValuehuLines,
                prop: 'huLinesValue',
                propColor: 'huLinesColor'
            }, {
                greenType: colorCodeParametrization.greenUnitsLines,
                greenValue: colorCodeParametrization.greenValueUnitsLines,
                redType: colorCodeParametrization.redUnitsLines,
                redValue: colorCodeParametrization.redValueUnitsLines,
                prop: 'unitsLinesValue',
                propColor: 'unitsLinesColor'
            }
        ];

        if (!colorCodeParametrization.dafaultConfig) {
            checkObject.forEach(x => {
                if(x.greenValue != 0 || x.redValue != 0){
                    this.checkTypeAndCalc(x.greenType, x.greenValue, x.redValue, x.prop, x.propColor);
                }
            });
        }
    }

    checkTypeAndCalc(greentype, greenValue, redValue, prop, propColor) {
        var num = this[prop].toFixed(2)
        let isGreen = (greentype == 1) ? num < greenValue  : num > greenValue;
        if (isGreen) {
            this[propColor] = 0;
            return;
        }

        let isRed = (greentype == 1) ? num > redValue : num < redValue;
        if (isRed)
            this[propColor] = 1;
    }

    isGreen(field, orders: number): boolean {
        return (orders == 0) ? false : this[field] == 0;
    }

    isRed(field, orders: number): boolean {
        return (orders == 0) ? false : this[field] == 1;
    }
}