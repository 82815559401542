import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../../../environments/environment";
import { AbstractService } from "../abstract.service";

@Injectable()
export class OrderService extends AbstractService<any> {

    constructor(public http: HttpClient) {
        super(http, env.host.sbaw, "/wrapper/sbaw/order");
    }

    getCountPendingOrders(establishmentId, depositorId) {
        return this.http.get(`${env.host.sbaw}${this.url}/pending-orders?establishmentId=${establishmentId}&depositorId=${depositorId}`);
    }

    download(pathWithParams: String) {
        return this.http.get(`${env.host.sbaw}${pathWithParams}`, { responseType: "blob" });
    }

    cancel(id, establishmentId, depositorId) {
        return this.http.delete(`${env.host.sbaw}${this.url}/${id}?establishmentId=${establishmentId}&depositorId=${depositorId}`);
    }

}