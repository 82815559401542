import { SbawProcessStatusConverter } from "../i18n.ts/sbaw-status-process";


export class FileStatusSbaw {
    STATUS = [
        { id: 0, name : new SbawProcessStatusConverter().transform(0) },
        { id: 1, name : new SbawProcessStatusConverter().transform(1) },
        { id: 2, name : new SbawProcessStatusConverter().transform(2) },
        { id: -2, name : new SbawProcessStatusConverter().transform(-2) },
    ];

    OVERVIEW_STATUS = [
        { id: 0, name : new SbawProcessStatusConverter().transform(0) },
        { id: 1, name : new SbawProcessStatusConverter().transform(1) },
    ];

    constructor(){

    }
}

