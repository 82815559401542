import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, observable } from "rxjs";
import { environment as env } from "../../../../environments/environment";
import { Page } from "../../models/page";
import { Inconsistency } from "../../models/sbaw/inconsistency";
import { BaseService } from "./base.service";
import { Pickinglist } from "../../models/sbaw/pickinglist";

@Injectable()
export class EdiService extends BaseService {
    constructor(private http: HttpClient) { super() }

    private pathEdi: String = '/wrapper/sbaw/edi';
    
    getInconsistencies(id: any, page: Page) {
        return this.http.get<Inconsistency[]>(`${env.host.sbaw}${this.pathEdi}/inconsistencies/${id}/?size=${page.size}&page=${page.pageNumber}&sort=${page.sortName},${page.sortOrder}`);
    }

    confirmEdi(key: any,  establishmentId: any, depositorId: any) : Observable<HttpResponse<any>> {
        return this.http.post<Pickinglist>(`${env.host.sbaw}${this.pathEdi}/confirm?key=${key}&establishmentId=${establishmentId}&depositorId=${depositorId}`, null,  
            {
                observe: 'response'
            }
        );
    }

    exportXlsx(id: number): Observable<Blob> {
        return this.http.get(`${env.host.sbaw}/wrapper/export/${id}`, { responseType: "blob" });
    }

}