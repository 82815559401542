export class Setting {
    id: any;
    domainId: any;
    establishmentId: any;
    blacklistVipType: any;
    productType: any;
    savingType: any;
    lineValue: any;
    defaultConfig: boolean;
    specialName: any;
    csvExportation: any;
    csvSeparator: any;
    senhaEnabled: any;
    senha: any;
    savings1: boolean;
    senhaConfirma: any;

    constructor() {
        this.id = 0;
        this.domainId = 0;
        this.establishmentId = 0;
        this.blacklistVipType = 0;
        this.productType = 0;
        this.savingType = 1;
        this.lineValue = 0.00;
        this.defaultConfig = false;
        this.specialName = 'DIFERENCIADO';
        this.csvExportation = 0;
        this.csvSeparator = ';';
        this.senhaEnabled = 0;
        this.senha = '';
        this.senhaConfirma = '';
        this.savings1 = false;

    }
}