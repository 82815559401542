export class ArrayDTO {
    public contentPickinglist;
    public contentOrder;
    public contentSet;
    public setId;
    public filterTable;
    public filter;
    public strategy;
    public maxOrder;
    public separetors;
    public type;
    public maxWaves;

    constructor() {
        this.contentPickinglist = new Array<String>();
        this.contentOrder = new Array<String>();
        this.contentSet = new Array<String>();
    }

    putArray(selecteds: Array<String>, index: number) {
        switch (index) {
            case 0:
                this.contentPickinglist = selecteds;
                break;
            case 1:
                this.contentOrder = selecteds;
                break;
            case 2:
                this.contentSet = selecteds;
                break;
        }
    }
}