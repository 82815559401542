import { Inconsistency } from "./inconsistency";
import { Pickinglist } from "./pickinglist";

export class Score {
    public pickinglist: Pickinglist;
    public orders: number;
    public products: number;
    public parts: number;
    public hu: number;
    public totalLines: number;
    public weight: number;
    public cubicMeter: number;
    public destinations: number;
    public inconsistencies: Inconsistency[];
    public inconsistency: number;
    public productCodes: String[];
    public destinationCodes: String[];

    constructor() {
        this.inconsistency = 0;
        this.orders = 0;
        this.products = 0;
        this.parts = 0.00;
        this.hu = 0.00;
        this.totalLines = 0.00;
        this.weight = 0.00;
        this.cubicMeter = 0.00;
        this.destinations = 0.00;
        this.inconsistencies = new Array() as Array<Inconsistency>;
        this.productCodes = new Array() as Array<String>;
        this.destinationCodes = new Array() as Array<String>;
    }
}