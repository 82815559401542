import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../../../environments/environment";
import { Page } from "../../models/page";
import { ArrayDTO } from "../../models/sbaw/dto/array-dto";
import { FiltersScreenDTO } from "../../models/sbaw/dto/custom-filters-dto";
import { Overview } from "../../models/sbaw/overview";
import { Pickinglist } from "../../models/sbaw/pickinglist";
import { AbstractService } from "../abstract.service";

@Injectable()
export class PickinglistService extends AbstractService<any> {

    constructor(public http: HttpClient) {
        super(http, env.host.sbaw, "/wrapper/sbaw/pickinglist");
    }

    getPickinglists(page: Page) {
        return this.http.get(`${env.host.sbaw}${this.url}/?size=${page.size}&page=${page.pageNumber}&sort=${page.sortName},${page.sortOrder}`);
    }

    getPickinglistsWithParams(dateIni: any, dateFin: any, fileName: any, status: any, establishmentId: any, depositorId: any, page: Page) {
        return this.http.get(`${env.host.sbaw}${this.url}/find-overview?dateIni=${dateIni}&dateFin=${dateFin}&fileName=${fileName}&status=${status}&establishmentId=${establishmentId}&depositorId=${depositorId}&size=${page.size}&page=${page.pageNumber}&sort=${page.sortName},${page.sortOrder}`);
    }

    findById(id: any) {
        return this.http.get<Pickinglist>(`${env.host.sbaw}${this.url}/${id}`);
    }

    cancel(id: any) {
        return this.http.delete(`${env.host.sbaw}${this.url}/${id}`);
    }

    cancelByIdAndEstablishmentAndDepositor(id: any, establishmentId: any, depositorId: any) {
        return this.http.delete(`${env.host.sbaw}${this.url}/${id}?establishmentId=${establishmentId}&depositorId=${depositorId}`);
    }

    overviewPostSelected(selecteds: any[], establishmentId: any, depositorId: any) {
        return this.http.post<Overview>(`${env.host.sbaw}${this.url}/overview-resume?selecteds=${this.getListQuery(selecteds)}&establishmentId=${establishmentId}&depositorId=${depositorId}`, null, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
    }

    filtersPostSelected(selecteds: any[], establishmentId: any, depositorId: any, filters: FiltersScreenDTO) {
        return this.http.post<Overview>(`${env.host.sbaw}${this.url}/filters-resume?selecteds=${this.getListQuery(selecteds)}&establishmentId=${establishmentId}&depositorId=${depositorId}`, filters, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
    }

    overviewPostFiltered(dateIni: any, dateFin: any, fileName: any, status: any, establishmentId: any, depositorId: any) {
        return this.http.post(`${env.host.sbaw}${this.url}/overview-resume-by-filters?dateIni=${dateIni}&dateFin=${dateFin}&fileName=${fileName}&status=${status}&establishmentId=${establishmentId}&depositorId=${depositorId}`, null);
    }

    filtersPostFiltered(dateIni: any, dateFin: any, fileName: any, status: any, establishmentId: any, depositorId: any, filters: FiltersScreenDTO) {
        return this.http.post(`${env.host.sbaw}${this.url}/filters-resume-by-filters?dateIni=${dateIni}&dateFin=${dateFin}&fileName=${fileName}&status=${status}&establishmentId=${establishmentId}&depositorId=${depositorId}`, filters);
    }

    overviewOrdersToSet(pickinglists: any[], orderIds: any[], filterTable: String, filter: String, establishmentId: any, depositorId: any) {
        let arrayDTO = new ArrayDTO();

        arrayDTO.putArray(pickinglists, 0);
        arrayDTO.putArray(orderIds, 1);
        arrayDTO.filter = filter;
        arrayDTO.filterTable = filterTable;

        return this.http.post<ArrayDTO>(`${env.host.sbaw}${this.url}/overview-orders-to-set?establishmentId=${establishmentId}&depositorId=${depositorId}`, arrayDTO);
    }

    filtersOrdersToSet(key: String, filterName: String) {
        return this.http.post<ArrayDTO>(`${env.host.sbaw}${this.url}/filters-orders-to-set?key=${key}`, filterName);
    }

    getListQuery(list) {
        let listParam = [];

        list.forEach(element => {
            listParam.push(element);
        });
        return listParam.join(',');
    }

}