import { AbstractService } from "../abstract.service";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment as env } from "../../../../environments/environment";
import { Injectable } from "@angular/core";
import { State } from "../../models/sbaw/state";
import { Observable } from "rxjs";

@Injectable()
export class MetropolitanRegionService extends AbstractService<State> {

  constructor(public http: HttpClient) {
    super(http, env.host.sbaw, "/wrapper/sbaw/metropolitan-region");
  }

  findByEstablishment(establishmentId): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.host}${this.url}/findAll?establishmentId=${establishmentId}`, { observe: 'response' });
  }

  findActive(establishmentId): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.host}${this.url}/findActive?establishmentId=${establishmentId}`, { observe: 'response' });
  }
}