export class FileManagementDTO {
    public canceled: any;
    public ignored: any;
    public pending: any;
    public partial: any;
    public processed: any;

    constructor() {
        this.ignored = 0;
        this.canceled = 0;
        this.pending = 0;
        this.partial = 0;
        this.processed = 0;
    }
}
