export class ColorCodeParametrization {
    public id: String;

    establishmentId: number;
    depositorId: number;

    redLinesOrders: any;
    redValueLineOrders: any;
    redUnitsLines: any;
    redValueUnitsLines: any;
    redHuLines: any;
    redValuehuLines: any;

    greenLinesOrders: any;
    greenValueLineOrders: any;
    greenUnitsLines: any;
    greenValueUnitsLines: any;
    greenHuLines: any;
    greenValuehuLines: any;

    dafaultConfig: boolean;

    constructor() {

    }
}