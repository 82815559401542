import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment as env } from "../../../../environments/environment";
import { ArrayDTO } from "../../models/sbaw/dto/array-dto";
import { BaseService } from "./base.service";

@Injectable()
export class AgroupingService extends BaseService {
    constructor(private http: HttpClient) { super() }

    private pathAgrouping: String = '/wrapper/sbaw/agrouping';

    postSetsToGroup0(selecteds: any[], separetors_0: any, strategy: any, establishmentId: number, depositorId: number) {
        let arrayDTO = new ArrayDTO();
        arrayDTO.separetors = separetors_0;
        arrayDTO.strategy = strategy;
        arrayDTO.putArray(selecteds, 2);
        arrayDTO.maxOrder = 1;

        return this.http.post(`${env.host.sbaw}${this.pathAgrouping}/make-agroupings?establishmentId=${establishmentId}&depositorId=${depositorId}`, arrayDTO, this.httpOptions);
    }

    postSetsToGroup1(selecteds: any[], separetors_1: any, strategy: any, maxOrders: any, type: any, establishmentId: number, depositorId: number) {
        let arrayDTO = new ArrayDTO();
        arrayDTO.separetors = separetors_1;
        arrayDTO.strategy = strategy;
        arrayDTO.putArray(selecteds, 2);
        arrayDTO.maxOrder = maxOrders;
        arrayDTO.type = type;

        return this.http.post(`${env.host.sbaw}${this.pathAgrouping}/make-agroupings?establishmentId=${establishmentId}&depositorId=${depositorId}`, arrayDTO, this.httpOptions);
    }

    postSetsToGroup2(selecteds: any[], strategy: any, maxOrders: any, maxWaves: any, establishmentId: number, depositorId: number) {
        let arrayDTO = new ArrayDTO();
        arrayDTO.putArray(selecteds, 2);
        arrayDTO.strategy = strategy;
        arrayDTO.maxOrder = maxOrders;
        arrayDTO.maxWaves = maxWaves;

        return this.http.post(`${env.host.sbaw}${this.pathAgrouping}/make-agroupings?establishmentId=${establishmentId}&depositorId=${depositorId}`, arrayDTO, this.httpOptions);
    }

    postConfirAgroupings(key: String) {
        return this.http.post(`${env.host.sbaw}${this.pathAgrouping}/confirm-agroupings/?key=${key}`, null);
    }

    downloadMiwas(id: number, establishmentId: number, depositorId: number): Observable<Blob> {
        return this.http.get(`${env.host.sbaw}${this.pathAgrouping}/download-miwas/${id}?establishmentId=${establishmentId}&depositorId=${depositorId}`, { responseType: "blob" });
    }

    downloadExportar(path: String): Observable<Blob> {
        return this.http.get(`${env.host.sbaw}${path}`, { responseType: "blob" });
    }

    downloadConsolidado(id: number, establishmentId: number, depositorId: number): Observable<Blob> {
        return this.http.get(`${env.host.sbaw}${this.pathAgrouping}/download-consolidado/${id}?establishmentId=${establishmentId}&depositorId=${depositorId}`, { responseType: "blob" });
    }

}