import { Score } from "./score";


export class Pickinglist {
    public id: any;
    public listName: String;
    public score: Score;
    public status: any;
    public key: any;
    public createdAt: any;

    constructor() {
        this.listName = "";
        this.score = new Score();
    }
}