import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ProviderService } from '../../core/services/sdm/provider-management/provider.service';
import { TranslatorService } from '../../core/translator/translator.service';
import { AbstractRegister } from '../register/abstract-register';
import { Depositor } from './../../core/models/sdm/depositor';
import { Establishment } from './../../core/models/sdm/establishment';
import { DepositorService } from './../../core/services/depositor.service';
import { DomainService } from './../../core/services/domain-service';
import { EstablishmentService } from './../../core/services/establishment.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { DestinataryService } from '../../core/services/sbaw/destinatary.service';

export abstract class AbstractGatewayRegister<T> extends AbstractRegister<T> {
  agents = [];
  agentsChange = [];
  solutions = [];
  depositors: Depositor[] = [];
  establishments: Establishment[] = [];
  destinataries = [];

  formSearch: FormGroup;

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    public _providerService: ProviderService,
    public domainService: DomainService,
    public establishmentService: EstablishmentService,
    public destinataryService: DestinataryService
  ) {
    super(location, toaster, translatorService, depositorService);

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishment: new FormControl('', [Validators.required]),
      depositor: new FormControl('', [Validators.required]),
      provider: new FormControl('', [Validators.required]),
      solutionId: new FormControl('', [Validators.required]),
      domainId: new FormControl('')
    });

    this.formSearch = new FormGroup({
      establishment: new FormControl('', [Validators.required]),
      depositor: new FormControl(''),
      provider: new FormControl(''),
      solutionId: new FormControl('')
    });
  }

  endPointToSearchForm() {
    const establishment = this.formSearch.get('establishment').value || '';
    const depositor = this.formSearch.get('depositor').value || '';
    const provider = this.formSearch.get('provider').value || '';
    const solutionId = this.formSearch.get('solutionId').value || '';

    return `${this.endPointSearch()}?establishmentId=${establishment}&depositorId=${depositor}&solutionId=${solutionId}&providerId=${provider}&`;
  }

  protected abstract endPointSearch(): string;

  submit() {
    if (this.formulario.valid) {
      this.fillDepositorWithId();
      if (this.formulario.get('id').value == null) {
        this.getService()
          .create(this.formulario.value)
          .subscribe(data => {
            this.formulario.patchValue(data);
            this.disabledForm();
            this.getGrid().reloadPage();
            this.showMessageActions();
          });
      } else {
        this.getService()
          .edit(this.formulario.getRawValue())
          .subscribe(() => {
            this.disabledForm();
            this.getGrid().reloadPage();
            this.showMessageActions('success', 'update');
          });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  formFillToEdit(row: object) {
    
    this.formulario.patchValue(row);
    this.fillDepositorWhenSelectGridCell(row);
    this.formulario
      .get('establishment')
      .setValue(row['depositor']['establishment']);
    this.formulario.enable();
    this.disableFields(
      ['establishment', 'depositor', 'provider', 'solutionId'],
      this.formulario,
      false
    );
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
  }

  getEstablishments() {
    this.establishmentService.findAllPath('/findAll').subscribe(data => {
      this.establishments = data['body']['content'];
    });
  }

  getSolutions() {
    this.domainService.findAllPath('/solution').subscribe(data => {
      this.solutions = data.body['content'];
    });
  }

  getAgents(depositor: Depositor) {
    this._providerService.findByDepositorId(depositor.id).subscribe(data => {
      if (data) {
        this.agents = data;
        this.agentsChange = data;

        if (this.agents.length === 1) {
          this.formulario.get('provider').setValue(this.agents[0]);
        }

      } else {
        this.agents = [];
        this.agentsChange = [];
        this.formulario.get('provider').setValue([]);
      }
    });
  }

  depositorChange(depositor) {
    if (depositor) {
      this.getAgents(depositor);
    } else {
      this.agents = [];
      this.agentsChange = [];
      this.formulario.get('provider').setValue([]);
    }
  }



  applyFilter() {
    if (this.formSearch.valid) {
      this.getGrid().reloadPage();
      return;
    }

    this.verificaValidacoesForm(this.formSearch);
  }

  reset() {
    this.formulario.reset();
    this.formulario.disable();
  }

  fileUpload(data) {
    if (data) {
      this.getGrid().reloadPage();
    }
  }

  backup() {
    this.getService()
      .backup()
      .subscribe(data => {
        this.getGrid().reloadPage();
      });
  }

  export() {
    this.getService()
      .export()
      .subscribe(data => {
        importedSaveAs(data, this.getFileName());
      });
  }

  template() {
    this.getService()
      .template()
      .subscribe(data => {
        importedSaveAs(data, this.getFileName());
      });
  }

  getFileName(): string {
    this.translator.translate
      .get(`pages.${this.getComponentName()}.excelFileName`)
      .subscribe(fileName =>
        fileName ? (this.fileName = fileName) : this.fileName
      );
    return `${this.fileName}.xlsx`;
  }

  establishmentChanged(establishment) {
    if (establishment) {
      this.depositorService
        .findByEstablishmentId(establishment.id)
        .subscribe(data => {
          this.depositors = data.body['content'];

          if (this.depositors.length === 1) {
            this.formulario.get('depositor').setValue(this.depositors[0]);
            this.getAgents(this.depositors[0]);
          }
        });
    } else {
      this.depositors = [];
      this.formulario.get('depositor').setValue([]);
    }
  }

  validEstablishmentAndDepositorSelection(establishment, depositor) {
    return (establishment && depositor);
  }

}
