import { DomainModel } from "./domain.model";
import { Role } from "./role.model";
import { Solution } from "./solution.model";
import { Establishment } from "./sdm/establishment";
import { UserEstablishments } from "./UserEstablishments.model";
import { UserDepositor } from "./userDepositor.model";
import { Depositor } from "./sdm/depositor";
import { Report } from "./report/report";

export class UserDomain {

    public id: number;
    public username: String;
    public password: String;
    public roles: Role[];
    public domain: DomainModel
    public establishments: Establishment[];
    public userEstablishments: UserEstablishments[]
    public userDepositor: UserDepositor;
    public depositors: Depositor[];
    public solutions: Solution[];
    public status: boolean;
    public authority: Role;
    public reports: Report[];

    constructor() {
        this.authority = new Role();
        this.roles = [];
        this.establishments = new Array() as Array<Establishment>;
        this.userEstablishments = new Array() as Array<UserEstablishments>;
        this.solutions = new Array() as Array<Solution>;
        this.domain = new DomainModel();
        this.status = true;
        this.reports = [];
    }
}