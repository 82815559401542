import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../../../environments/environment";
import { AbstractService } from "../abstract.service";
import { GroupSetModel } from "../../models/sbaw/group-set";


@Injectable()
export class ProductService extends AbstractService<any> {

    constructor(public http: HttpClient) {
      super(http, env.host.sbaw, "/wrapper/sbaw/product");
    }

}