import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment as env } from "../../../environments/environment";
import { Solution } from "../models/solution.model";
import { AbstractService } from "./abstract.service";
import { Observable } from 'rxjs';

@Injectable()
export class RolesService extends AbstractService<Solution> {

  constructor(public http: HttpClient) {
    super(http, env.host.sbaw, "/wrapper/roles");
  }

}