import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../../../environments/environment";
import { Page } from "../../models/page";
import { set } from "../../models/sbaw/set";
import { AbstractService } from "../abstract.service";

@Injectable()
export class SetService extends AbstractService<set> {

    constructor(public http: HttpClient) {
        super(http, env.host.sbaw, "/wrapper/sbaw/set");
    }

    getCountPendingSets(establishmentId, depositorId) {
        return this.http.get(`${env.host.sbaw}${this.url}/pending-sets?establishmentId=${establishmentId}&depositorId=${depositorId}`);
    }

    getSets(establishmentId: any, depositorId: any, page: Page) {
        return this.http.get(`${env.host.sbaw}${this.url}?establishmentId=${establishmentId}&depositorId=${depositorId}&size=${page.size}&page=${page.pageNumber}&sort=${page.sortName},${page.sortOrder}`);
    }

    getPendingSets(dateIni: any, dateFin: any, table: any, filter: any, establishmentId: any, depositorId: any, page: Page) {
        return this.http.get(`${env.host.sbaw}${this.url}/pending-filtered/?dateIni=${dateIni}&dateFin=${dateFin}&table=${table}&filter=${filter}&establishmentId=${establishmentId}&depositorId=${depositorId}&size=${page.size}&page=${page.pageNumber}&sort=${page.sortName},${page.sortOrder}`);
    }

    cancel(id: any, establishmentId: any, depositorId: any, status: any) {
        return this.http.delete(`${env.host.sbaw}${this.url}/${id}?establishmentId=${establishmentId}&depositorId=${depositorId}&status=${status}`);
    }

}