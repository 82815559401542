import { sbawConfigConverter } from "../i18n.ts/sbaw-config-converter";
import { sbawConfigMoreLess } from "../i18n.ts/sbaw-config-more-less";
import { sbawSavingTypeConverter } from "../i18n.ts/sbaw-saving-type-converter";
import { sbawCSVExportationConverter } from "../i18n.ts/sbaw-csv-exportation-converter";
export class SbawConfig {

    replaceOptions = [
        { id: 0, name: new sbawConfigConverter().transform(0) },
        { id: 1, name: new sbawConfigConverter().transform(1) },
        { id: 2, name: new sbawConfigConverter().transform(2) },
    ];

    colorCodeType = [
        { id: 0, name: new sbawConfigMoreLess().transform(0) },
        { id: 1, name: new sbawConfigMoreLess().transform(1) },
    ];

    savingOptions = [
        { id: 0, name: new sbawSavingTypeConverter().transform(0) },
        { id: 1, name: new sbawSavingTypeConverter().transform(1) },
    ]

    csvOptions = [
        { id: 0, name: new sbawCSVExportationConverter().transform(0) },
        { id: 1, name: new sbawCSVExportationConverter().transform(1) },
    ]
    constructor() {

    }
}

