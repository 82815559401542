import { HttpClient } from "@angular/common/http";
import { AbstractService } from "../abstract.service";
import { Injectable } from "@angular/core";
import { environment as env } from "../../../../environments/environment";

@Injectable()
export class DestinataryService extends AbstractService<any> {

    constructor(public http: HttpClient) {
        super(http, env.host.sbaw, "/wrapper/sbaw/destinatary");
    }


}