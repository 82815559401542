
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from "../../../../environments/environment";
import { UserDomain } from "../../models/user-domain.model";
import { AbstractService } from "../abstract.service";

@Injectable()
export class SdmUserDomainService extends AbstractService<UserDomain> {

    constructor(public http: HttpClient) {
      super(http, env.host.sbaw, "/wrapper/sdm-user-domain");
    }

}
